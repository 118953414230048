import {DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown} from "reactstrap";
import userAvatar from "../../assets/avatar.png";
import {Link} from "react-router-dom";
import {CreditCard, Power, Settings, User} from "react-feather";

const UserDropdown = props => {

    const userData = JSON.parse(localStorage.getItem('user_data'))
    const email = userData.email

    const onLogout = () => {
        if (typeof props.onLogout === "function") {
            props.onLogout()
        }
    }

    return (
        <UncontrolledDropdown tag='li' className='dropdown-user nav-item'>
            <DropdownToggle href='/' tag='a' className='nav-link dropdown-user-link' onClick={e => e.preventDefault()}>
                <img src={userAvatar} alt={"user profile"} height={40} width={40} className="rounded-circle"/>
            </DropdownToggle>
            <DropdownMenu end>
                <DropdownItem>
                    <div className="d-flex">
                        <div className="flex-shrink-0 me-3">
                            <div className="avatar avatar-online">
                                <img src={userAvatar} alt={"user profile"} height={40} width={40} className="h-auto rounded-circle"/>
                            </div>
                        </div>
                        <div className="flex-grow-1">
                            <span className="fw-semibold d-block">{localStorage.getItem('nama_pengguna')}</span>
                            <small className="text-muted">{email}</small>
                        </div>
                    </div>
                </DropdownItem>
                <DropdownItem tag={Link} to='/profile'>
                    <User size={14} className='me-2' />
                    <span className='align-middle'>Profile</span>
                </DropdownItem>
                <DropdownItem tag={Link} to='/setting'>
                    <Settings size={14} className='me-2' />
                    <span className='align-middle'>Settings</span>
                </DropdownItem>
                <DropdownItem tag={Link} to='/billing'>
                    <CreditCard size={14} className='me-2' />
                    <span className='align-middle'>Billing</span>
                </DropdownItem>
                <DropdownItem onClick={() => onLogout()}>
                    <Power size={14} className='me-2' />
                    <span className='align-middle'>Logout</span>
                </DropdownItem>
            </DropdownMenu>
        </UncontrolledDropdown>
    )
}

export default UserDropdown;
import React, {useRef, useState} from "react";
import { Icon } from '@iconify/react';
import "../styles/participant.css"
import arrowRight from '@iconify/icons-bi/x-circle';
import { ProSidebar, SidebarHeader, SidebarContent } from 'react-pro-sidebar';
import 'react-pro-sidebar/dist/css/styles.css';
import cameraVideo from "@iconify/icons-bi/camera-video";
import micIcon from "@iconify/icons-bi/mic";
import micMute from "@iconify/icons-bi/mic-mute";
import cameraVideoOff from "@iconify/icons-bi/camera-video-off";

const Participants = ({users, openUser, setOpenUser, setMuteUser, setOffVideo}) => {
    const userRef = useRef(null)
    const [audioIcon, setAudioIcon] = useState(micIcon)
    const [videoIcon, setVideoIcon] = useState(cameraVideo)

    const onMute = (val) => {
        if (typeof setMuteUser === "function") {
            setAudioIcon(micMute)
            setMuteUser(val)

        }
    }

    const onOffVideo = (val) => {
        if (typeof setOffVideo === "function") {
            setVideoIcon(cameraVideoOff)
            setOffVideo(val)
        }
    }

    return (

        <ProSidebar collapsed={!openUser} collapsedWidth="0px" className="user-pro-sidebar">
            <SidebarHeader>
                <div className="user-header">
                <button className="outline-btn"
                    onClick={() => { setOpenUser(!openUser) }}>
                    <Icon icon={arrowRight} style={{ fontSize: '30px' }} />
                </button>
            <h2>
                Daftar Peserta
            </h2>
            </div>
        </SidebarHeader>
        <SidebarContent className="user-pro-sidebar-content">
            <ul>
            {
               users !== null ?  users.map((item, index) => {
                    return (
                        <li key={index} style={{height: "30px", marginTop: "10px"}}>
                            <div style={{float: "left"}}>{item.name} </div>
                            <div style={{float: "right"}}>
                                {
                                    item.mic ? <Icon
                                        icon={audioIcon}
                                        className="user-control"
                                        data-tip="close mic"
                                        onClick={() => {
                                            onMute(item.id)
                                        }} /> : <Icon
                                        icon={micMute}
                                        data-tip="open mic"
                                        className="user-control"
                                        onClick={() => {

                                        }} />
                                }
                                {
                                    item.video ?  <Icon
                                        icon={videoIcon}
                                        data-tip="off camera"
                                        className="user-control"
                                        onClick={() => {
                                            onOffVideo(item.id)
                                        }}
                                    /> : <Icon
                                        icon={cameraVideoOff}
                                        className="user-control"
                                        data-tip="open camera"
                                        onClick={() => {

                                        }} />
                                }


                            </div>
                        </li>
                    )
                }) : ''
            }
            </ul>
            <div ref={userRef} ></div>
        </SidebarContent>
        </ProSidebar>
    )
}

export default Participants;
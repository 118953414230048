import Userdropdown from "./Userdropdown";
import {connect} from "react-redux";
import {useHistory} from "react-router-dom";

const Header = (props) => {

    const {
        setName,
        setUserId,
        setAuth,
        setIsAdmin
    } = props

    const history = useHistory()

    const onLogout = () => {
        setAuth(false)
        setUserId('')
        setName('')
        setIsAdmin('no')
        localStorage.removeItem('TeamsToken')
        history.push("/")
    }

    return (
        <div className="header">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-xxl-12">
                        <div className="header-content">
                            <div className="header-left">
                                <div className="search">

                                </div>
                            </div>

                            <div className="header-right">
                                <div className="dark-light-toggle">
                  <span className="dark">
                    <i className="bi bi-moon"></i>
                  </span>
                                    <span className="light">
                    <i className="bi bi-brightness-high"></i>
                  </span>
                                </div>
                                <Userdropdown onLogout={onLogout} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = state => {
    return {
        name: state.userDetails.name,
        userId: state.userDetails.userId,
        auth: state.userDetails.auth,
        isAdmin: state.userDetails.isAdmin
    }
}

const mapDispatchToProps = dispatch => {
    return {
        setIsAdmin: data => {
            dispatch({
                type: 'SET_IS_ADMIN',
                isAdmin: data,
            })
        },
        setName: data => {
            dispatch({
                type: 'SET_NAME',
                name: data,
            })
        },
        setUserId: data => {
            dispatch({
                type: 'SET_USER_ID',
                userId: data,
            })
        },
        setAuth: data => {
            dispatch({
                type: 'SET_AUTH',
                auth: data,
            })
        }
    }
}




export default connect(mapStateToProps, mapDispatchToProps)(Header)
import {Dialog, Slide} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import React from "react";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});
const AlertDialog = props => {

    const handleClose = () => {
        if (typeof props.onClose === "function") {
            props.onClose(false)
        }
    }

    const handleRightButton = () => {
        if (typeof props.onRightAction === "function") {
            props.onRightAction()
        }
    }

    const handleLeftButton = () => {
        if (typeof props.onLeftAction === "function") {
            props.onLeftAction()
        }
    }

    return (
        <Dialog open={props.showDialog} onClose={handleClose} TransitionComponent={Transition}
                keepMounted
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description">
            <DialogTitle id="alert-dialog-title">{props.title}</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {props.message}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                    <Button onClick={handleLeftButton} color="primary">
                        {props.btnTextLeft}
                    </Button>
                    <Button onClick={handleRightButton}
                            color="primary"
                            autoFocus>
                        {props.btnTextRight}
                    </Button>
            </DialogActions>
        </Dialog>
    )

}

export default AlertDialog
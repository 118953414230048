import {ModalBody, Modal, ModalHeader, Row, Col, Label, Input, Button} from "reactstrap";
import {useState} from "react";
import {toast} from "react-toastify";
const CreateRoom = props => {

    const [meetingId, setMeetingId] = useState(props.meetingId)
    const [securityOption, setSecurityOption] = useState('none')
    const [buttonText, setButtonTxt] = useState('Submit')
    const [passwordTxt, setPasswordTxt] = useState('')

    const changeBtnText = (val) => {
        setButtonTxt(val)
    }

    const handleDiscard = () => {
        if (typeof props.onClose === "function") {
            props.onClose(false)
        }
    }

    const onSubmit = (val) => {
        if (typeof props.onSave === "function") {
            if(meetingId === '') {
                toast.error(`Meeting ID tidak boleh kosong`, {
                    position: 'top-left'
                });
                return false
            }
            changeBtnText('Loading...')
            const arrData = {meetingId,securityOption,passwordTxt}
            props.onSave(val, arrData)
        }
    }

    const onChangeSecurity = (val) => {
        setSecurityOption(val)
    }

    return (
        <Modal isOpen={props.showModal} toggle={() => handleDiscard()} className='modal-dialog-centered modal-xl'>
            <ModalHeader  className='bg-transparent' toggle={() => handleDiscard()}>Buat Meeting</ModalHeader>
                <ModalBody className='px-sm-5 mx-50 pb-5'>
                    <Row>
                        <Col xs={12}>
                            <Label className='form-label' for='nama'>
                                Kode Meeting
                            </Label>
                            <Input type='text' id='nama' value={meetingId} onChange={(val) => setMeetingId(val.target.value)} name='meetingid' bsSize='md' placeholder='' />
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12}>
                            <Label className='form-label'>
                                Security
                            </Label>
                            <div className="row">
                                <div className="col-md-4 mb-md-0 mb-2">
                                    <div className="form-check custom-option custom-option-basic">
                                        <label className="form-check-label custom-option-content" htmlFor="radioNone">
                                            <Input
                                                name="securityOption"
                                                className="form-check-input"
                                                id="radioNone"
                                                type="radio"
                                                value="none"
                                                checked={securityOption === 'none'}
                                                onChange={(val) => onChangeSecurity(val.target.value)}
                                            />
                                            <span className="custom-option-header">
                                            <span className="h6 mb-0">None</span>
                                            <span></span>
                                          </span>
                                                <span className="custom-option-body">
                                            <small> Pengguna bebas join </small>
                                          </span>
                                        </label>
                                    </div>
                                </div>
                                <div className="col-md-4 mb-md-0 mb-2">
                                    <div className="form-check custom-option custom-option-basic">
                                        <label className="form-check-label custom-option-content" htmlFor="radioPassword">
                                            <Input
                                                name="securityOption"
                                                className="form-check-input"
                                                id="radioPassword"
                                                type="radio"
                                                value="password"
                                                checked={securityOption === 'password'}
                                                onChange={(val) => onChangeSecurity(val.target.value)}
                                            />
                                            <span className="custom-option-header">
                                        <span className="h6 mb-0">Password</span>
                                        <span></span>
                                      </span>
                                            <span className="custom-option-body">
                                        <small> User perlu input password buat join </small>
                                      </span>
                                        </label>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-check custom-option custom-option-basic">
                                        <label className="form-check-label custom-option-content" htmlFor="radioApproval">
                                            <Input
                                                name="securityOption"
                                                className="form-check-input"
                                                id="radioApproval"
                                                type="radio"
                                                value="approval"
                                                checked={securityOption === 'approval'}
                                                onChange={(val) => onChangeSecurity(val.target.value)}
                                            />
                                            <span className="custom-option-header">
                                        <span className="h6 mb-0">Perlu Approval</span>
                                        <span></span>
                                      </span>
                                            <span className="custom-option-body">
                                        <small>User perlu konfirmasi dari Admin</small>
                                      </span>
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                    {
                        securityOption === 'password' ?
                        <Row>
                            <Col xs={12}>
                                <Label className='form-label' for='nama'>
                                    Password
                                </Label>
                                <Input type='password' id='password' value={passwordTxt} onChange={(val) => setPasswordTxt(val.target.value)} name='password' bsSize='md' placeholder='' />
                            </Col>
                        </Row> : ''
                    }
                    <Row>
                        <Col xs={12} className='text-center mt-2'>
                            <Button className='me-1' color='primary' onClick={(e) => onSubmit(e)}>
                                {buttonText}
                            </Button>
                            <Button outline type='reset' onClick={handleDiscard}>
                                Batal
                            </Button>
                        </Col>
                    </Row>
                </ModalBody>
        </Modal>
    )
}

export default CreateRoom;
import '../styles/homenew.css'
import "../styles/calender.css";
import Header from "./layout/Header";
import Sidebar from "./layout/Sidebar";
import React, {Fragment, useRef} from "react";
import { Menu } from 'react-feather'
import '@fullcalendar/react/dist/vdom'
import FullCalendar from '@fullcalendar/react'
import listPlugin from '@fullcalendar/list'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin from '@fullcalendar/interaction'
import { Card, CardBody, Col } from 'reactstrap'
import Footer from "./layout/Footer";
const Calender = () => {

    const calendarRef = useRef(null)
    const calendarsColor = {
        Business: 'primary',
        Holiday: 'success',
        Personal: 'danger',
        Family: 'warning',
        ETC: 'info'
    }

    const calendarOptions = {
        events: [],
        plugins: [interactionPlugin, dayGridPlugin, timeGridPlugin, listPlugin],
        initialView: 'dayGridMonth',
        headerToolbar: {
            start: 'sidebarToggle, prev,next, title',
            end: 'dayGridMonth,timeGridWeek,timeGridDay,listMonth'
        },
        /*
          Enable dragging and resizing event
          ? Docs: https://fullcalendar.io/docs/editable
        */
        editable: true,

        /*
          Enable resizing event from start
          ? Docs: https://fullcalendar.io/docs/eventResizableFromStart
        */
        eventResizableFromStart: true,

        /*
          Automatically scroll the scroll-containers during event drag-and-drop and date selecting
          ? Docs: https://fullcalendar.io/docs/dragScroll
        */
        dragScroll: true,

        /*
          Max number of events within a given day
          ? Docs: https://fullcalendar.io/docs/dayMaxEvents
        */
        dayMaxEvents: 2,

        /*
          Determines if day names and week names are clickable
          ? Docs: https://fullcalendar.io/docs/navLinks
        */
        navLinks: true,

        eventClassNames({ event: calendarEvent }) {
            // eslint-disable-next-line no-underscore-dangle
            const colorName = calendarsColor[calendarEvent._def.extendedProps.calendar]

            return [
                // Background Color
                `bg-light-${colorName}`
            ]
        },

        eventClick({ event: clickedEvent }) {
           // dispatch(selectEvent(clickedEvent))
           // handleAddEventSidebar()

            // * Only grab required field otherwise it goes in infinity loop
            // ! Always grab all fields rendered by form (even if it get `undefined`) otherwise due to Vue3/Composition API you might get: "object is not extensible"
            // event.value = grabEventDataFromEventApi(clickedEvent)

            // eslint-disable-next-line no-use-before-define
            // isAddNewEventSidebarActive.value = true
        },

        customButtons: {
            sidebarToggle: {
                text: <Menu className='d-xl-none d-block' />,
                click() {
                   // toggleSidebar(true)
                }
            }
        },

        dateClick(info) {
          /*  const ev = blankEvent
            ev.start = info.date
            ev.end = info.date
            dispatch(selectEvent(ev))
            handleAddEventSidebar()*/
        },

        /*
          Handle event drop (Also include dragged event)
          ? Docs: https://fullcalendar.io/docs/eventDrop
          ? We can use `eventDragStop` but it doesn't return updated event so we have to use `eventDrop` which returns updated event
        */
        eventDrop({ event: droppedEvent }) {
           // dispatch(updateEvent(droppedEvent))
           // toast.success('Event Updated')
        },

        /*
          Handle event resize
          ? Docs: https://fullcalendar.io/docs/eventResize
        */
        eventResize({ event: resizedEvent }) {
          //  dispatch(updateEvent(resizedEvent))
           // toast.success('Event Updated')
        },

        ref: calendarRef,
    }

    return (
        <Fragment>
            <div id="main-wrapper">
                <Header />
                <Sidebar />
                <div className="container-xl mt-3">
                    <div className='app-calendar overflow-hidden border'>
                        <div className="row g-0">
                            <div className="col app-calendar-sidebar flex-grow-0 overflow-hidden d-flex flex-column" id="app-calendar-sidebar">
                                <div className="border-bottom p-4 my-sm-0 mb-2">
                                    <div className="d-grid">
                                        <button
                                            className="btn btn-primary btn-toggle-sidebar">
                                            <i className="ti ti-plus me-1"></i>
                                            <span className="align-middle">Tambah Meeting</span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <Col className='position-relative'>
                                <Card className='shadow-none border-0 mb-0 rounded-0'>
                                    <CardBody className='pb-0'>
                                        <FullCalendar {...calendarOptions} />{' '}
                                    </CardBody>
                                </Card>
                            </Col>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        </Fragment>
    )

}

export default Calender
import React, { useEffect, useState } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom"
import { prodUrl as url } from "./Config/config.json"
import { connect } from 'react-redux';
import Homenew from "./components/Homenew";
import Login from "./components/Login";
import Register from "./components/Register";
import Dashboard from "./components/Dashboard";
import Home from "./components/Home";
import Meet from "./components/Meet";
import ForgotPassword from "./components/ForgotPassword";
import Calender from "./components/Calender";

const Routing = (props) => {
  //const history = useHistory();
  const { auth } = props

 /* useEffect(() => {
    const urlArray = window.location.href.split("/")
    // Redirect to meeting and join room if url was shared 
    var roomIdFormat = /[a-fA-F0-9]{8}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{12}/;
    if (roomIdFormat.test(urlArray[urlArray.length - 1])) {
      setJoiningRoom(urlArray[urlArray.length - 1])
      const path = urlArray.find(ele => ele === 'home' || ele === 'teams')
      if (path)
        setJoiningPath('home')
      history.push("/")
    }
    else if (roomIdFormat.test(urlArray[urlArray.length - 2])) {
      setJoiningRoom(urlArray[urlArray.length - 2])
      const path = urlArray.find(ele => ele === 'home' || ele === 'teams')
      if (path)
        setJoiningPath(path)
      history.push("/")
    }
    // if not authorized then redirect to sign in
  }, [])*/

  return (
    <>
      {
        !auth
          ? <Switch>
              <Route exact path="/" component={Homenew} />
            <Route path="/newhome" component={Homenew} />
            <Route path="/signin" component={Login} />
              <Route path="/forgot" component={ForgotPassword} />
            <Route path="/register" component={Register} />
              <Route path="/join" component={Home} />
              <Route exact path="/join-room/:teamId" component={Home} />
              <Route exact path="/teams/:teamId" component={Meet} />
          </Switch>
          :
          <Switch>
            <Route exact path="/" component={Homenew} />
            <Route path="/newhome" component={Homenew} />
            <Route path="/dashboard" component={Dashboard} />
            <Route path="/join" component={Home} />
            <Route path="/calender" component={Calender} />
            <Route exact path="/join-room/:teamId" component={Home} />
            <Route exact path="/teams/:teamId" component={Meet} />
          </Switch>
      }
    </>
  )
}

function App(props) {
  const [loader, setLoader] = useState(true)
  const { auth, setJoiningRoom, setJoiningPath } = props


  useEffect(() => {

    fetch(url).then((data) => {

      setLoader(false)
    }).catch(err => {
      console.log(err)
    })
  }, [])
  return (
    <BrowserRouter>
      {
        loader ?

          <div className="center">
            <div className="wave"></div>
            <div className="wave"></div>
            <div className="wave"></div>
            <div className="wave"></div>
            <div className="wave"></div>
            <div className="wave"></div>
            <div className="wave"></div>
            <div className="wave"></div>
            <div className="wave"></div>
            <div className="wave"></div>
          </div>

          :
          <Routing auth={auth} setJoiningRoom={setJoiningRoom} setJoiningPath={setJoiningPath} />


      }
    </BrowserRouter>
  );
}



const mapStateToProps = state => {
  return {
    auth: state.userDetails.auth,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    setJoiningRoom: data => {
      dispatch({
        type: 'SET_JOINING_ROOM',
        joiningRoom: data,
      })
    },
    setJoiningPath: data => {
      dispatch({
        type: 'SET_JOINING_PATH',
        joiningPath: data,
      })
    }
  }
}



export default connect(mapStateToProps, mapDispatchToProps)(App)

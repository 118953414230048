import React, { useEffect, useRef, useState } from "react";
import { useHistory, Link } from "react-router-dom"
import UserVideo from "./userWindow"
import "../styles/home.css"
import { Icon } from '@iconify/react';
import micMute from '@iconify/icons-bi/mic-mute';
import micIcon from '@iconify/icons-bi/mic';
import cameraVideo from '@iconify/icons-bi/camera-video';
import cameraVideoOff from '@iconify/icons-bi/camera-video-off';
import cameraVideoOffFill from '@iconify/icons-bi/camera-video-off-fill';
import documentCopy20Filled from '@iconify/icons-fluent/document-copy-20-filled';
import Avatar from "../assets/avatar.png";
import Group_Connect from "../assets/home-right-vector.png";
import { Toggler, MediaInit } from "../utils/utilityFunctions"
import { connect } from 'react-redux';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Modal from 'react-modal';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import Loader from "react-loader-spinner";
import {Input} from "reactstrap";
import {makeMeetingId} from "../utils/utils";
import axios from "axios";
import { prodUrl as url } from "../Config/config.json"
import {io} from "socket.io-client";

const Home = (props) => {
  const {
    mic,
    setMic,
    camera,
    auth,
    setCamera,
    setStream,
    name,
    setName,
    userId,
    setUserId,
    stream,
    setSocket,
    setAudioDevices,
    securityOption,
    setVideoDevices
  } = props;
  const history = useHistory();
  const hostRef = useRef();
  const home_video_style = {
    width: "100%",
    borderRadius: "27px 27px 0 0",
    height: "100%",
    objectFit: "contain",
    display: camera ? "block" : "none"
  }
  const [modalIsOpen, setmodalIsOpen] = useState(false)
  const [stable, setStable] = useState(false)
  const [nama,setNama] = useState('')
  const [kodeMeeting,setKodeMeeting] = useState('')
  const [passwordMeeting,setPasswordMeeting] = useState('')
  const socketRef = useRef();
  const customStylesModal = {
    overlay: {
      backgroundColor: 'rgba(0,0,0,0.7)'
    },
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      padding: '20px'
    },
  };

  const roomID = props.match.params.teamId;
  useEffect(() => {
    MediaInit({ camera, mic, hostRef, setStream, setAudioDevices, setVideoDevices }).then((stream) => {
      setStream(stream)
      toast.info('Devices sudah ditemukan')
      setStable(true)
    })
      .catch(err => {
        toast.error('Devices tidak ditemukan')
      })
  }, [camera, mic, hostRef, setStream, setAudioDevices, setVideoDevices])
  useEffect(() => {
    if (stream) {
      if(typeof stream.getVideoTracks === "function")
      {
        stream.getVideoTracks()[0].enabled = camera

      }
      if(typeof stream.getAudioTracks === "function") {
        stream.getAudioTracks()[0].enabled = mic
      }

    }
    setNama(name)
  }, [mic, camera, stream])

  const onJoinRoom = () => {
    if(!auth) {
       if(nama === '') {
         toast.error("Nama tidak boleh kosong")
         return false;
       }
    }
    let idUser = makeMeetingId(10)
    if(roomID === undefined || roomID === null) {
      if(userId === '' || userId === null || userId === undefined){
        idUser = userId
      }
      axios({
        url: `${url}/joinRoom`,
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('TeamsToken')}`
        },
        data: {
          roomID: kodeMeeting,
          userId: idUser,
          userName: nama,
          password: passwordMeeting
        }
      }).then(data => {
        if(data.data.status) {
          localStorage.setItem('nama_pengguna', nama)
          setName(nama)
          if(userId === '' || userId === null || userId === undefined) {
            setUserId(idUser)
            localStorage.setItem('user_id', idUser)
          }
          if(securityOption === 'approval'){
            needPermitJoin(kodeMeeting)
          }
          else {
            history.push(`/teams/${roomID}`)
          }
        } else {
          toast.error(data.data.message)
        }

      }).catch(err => {

        toast.error("ID Meeting Salah")

      })
    } else {

      axios({
        url: `${url}/checkroom`,
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('TeamsToken')}`
        },
        data: {
          roomID: roomID,
          userId: idUser,
          userName: nama,
          password: passwordMeeting
        }
      }).then(data => {
        if(data.data.status) {
          localStorage.setItem('nama_pengguna', nama)
          setName(nama)
          if(userId === '' || userId === null || userId === undefined) {
            setUserId(idUser)
            localStorage.setItem('user_id', idUser)
          }
          if(securityOption === 'approval'){
            needPermitJoin(roomID)
          }
          else {
            history.push(`/teams/${roomID}`)
          }

        } else {
          toast.error(data.data.message)
        }

      }).catch(err => {

        toast.error("ID Meeting Salah")

      })

      /*localStorage.setItem('nama_pengguna', nama)
      setName(nama)
      const idUser = makeMeetingId(10)
      setUserId(idUser)
      localStorage.setItem('user_id', idUser)
      history.push(`/teams/${roomID}`)*/
    }
  }

  const needPermitJoin = (idMeeting) => {
    const socketNew = io.connect(`${url}`)
    socketRef.current = socketNew
    socketRef.current.emit("permit join", {roomID: idMeeting ,nama: nama})
    socketRef.current.on("permit status", (statusPermit) => {
       if(statusPermit) {
         history.push(`/teams/${idMeeting}`)
       } else {
         toast.error("Anda tidak diijinkan untuk ikut Meeting")
       }
    });
  }

  const ToggleState = (state, setState) => {
    Toggler(state, setState);
  }

  return (
    <div className="container-home">
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={() => {
          setmodalIsOpen(false)
        }}
        style={customStylesModal}
        id="modal-home"
        contentLabel="Name Modal"
      >
        <h3>Get your link</h3>
        <div className="modal-content-home">
          <Link to={`/teams/${roomID}`} style={{color:'white'}}>{window.location.href.split('/')[0]+"//"+window.location.href.split('/')[2]+"/teams/"+roomID}</Link>
          <CopyToClipboard text={window.location.href.split('/')[0]+"//"+window.location.href.split('/')[2]+"/teams/"+roomID}
          onCopy={()=>{
            toast.dark('Link copied!')
          }}>
          <Icon icon={documentCopy20Filled} 
          id="copy-to-clipboard-icon" />
          </CopyToClipboard>
        </div>
      </Modal>
      <div className="home-side-left">
        <a className="logo-home" style={{color:"white"}} href="/">ALS</a>
        <div className="video-box">

          <div className="video">
            <UserVideo hostRef={hostRef} style={home_video_style} muted={true} />
          </div>

          {!camera
            ?
            <div className="video-off-box">
              <Icon icon={cameraVideoOffFill}
                className="video-off"
              />
              <h3>Camera is Off</h3>
            </div>
            :
            null}
          <div className="footer-options-video">
            {
              mic
                ?
                <Icon icon={micIcon}
                  className="video-set-buttons"
                  onClick={() => { ToggleState(mic, setMic) }}
                />
                :
                <Icon icon={micMute}
                  className="video-set-buttons"
                  onClick={() => { ToggleState(mic, setMic) }}
                />
            }
            {
              camera
                ?
                <Icon icon={cameraVideo}
                  className="video-set-buttons"
                  onClick={() => { ToggleState(camera, setCamera) }}
                />
                :
                <Icon icon={cameraVideoOff}
                  className="video-set-buttons"
                  onClick={() => { ToggleState(camera, setCamera) }}
                />
            }
          </div>
        </div>
      </div>
      <div className="home-side-right">
        <div className="entry-box">
          <img src={Avatar} className="avatar-home" alt="Avatar" />
          <h1 style={{marginTop: "30px"}}>Join Meeting !</h1>
          {!stable?<><Loader
          type="BallTriangle"
          color="#00BFFF"
          height={30}
          width={30} />
          <h6>Koneksi Device ! Silahkan tunggu</h6></>:null}

            { roomID === undefined || roomID === null ?  <div className="mb-1 ml-1 mr-1">
                      <label>Kode Meeting</label>
                      <Input type='text' onChange={(e) => {
                        setKodeMeeting( e.target.value )}} autoFocus />
                    </div> : ''}
                <div className="mb-1 ml-1 mr-1">
                  <label>Nama</label>
                    <Input type='text' value={nama} onChange={(e) => {
                  setNama( e.target.value )}} />
                </div>
          {
            securityOption === 'password' ? <div className="mb-1 ml-1 mr-1">
                <label>Password</label>
                    <Input type='text' value={passwordMeeting} onChange={(e) => {
                setPasswordMeeting( e.target.value )}} />
            </div> : ''
          }

          <div className="home-entry-options">
            <button
              className={`home-entry-buttons ${!stable?"home-entry-buttons-disabled":''}`}
              onClick={() => {
                onJoinRoom()
              }}
            >Join Now</button>
            { roomID !== undefined ?
            <button
              className="home-entry-buttons"
              onClick={() => {
                setmodalIsOpen(true)
              }}
            >
              Share Link
            </button>
                : '' }
          </div>
        </div>
        <img alt="Avatar Konek" src={Group_Connect} className="home-bottom-vector" />
      </div>
      <ToastContainer />
    </div>)
}

const mapStateToProps = state => {
  return {
    email: state.userDetails.email,
    name: state.userDetails.name,
    userId: state.userDetails.userId,
    auth: state.userDetails.auth,
    mic: state.userDetails.mic,
    camera: state.userDetails.camera,
    stream: state.userDetails.stream,
    videoDevices: state.userDetails.videoDevices,
    isAdmin: state.userDetails.isAdmin,
    securityOption: state.userDetails.securityOption,
    audioDevices: state.userDetails.audioDevices
  }
}

const mapDispatchToProps = dispatch => {
  return {

    setEmail: data => {
      dispatch({
        type: 'SET_EMAIL',
        email: data,
      })
    },
    setUserId: data => {
      dispatch({
        type: 'SET_USER_ID',
        userId: data,
      })
    },
    setName: data => {
      dispatch({
        type: 'SET_NAME',
        name: data
      })
    },
    setAuth: data => {
      dispatch({
        type: 'SET_AUTH',
        auth: data,
      })
    },
    setMic: data => {
      dispatch({
        type: 'SET_MIC',
        mic: data
      })
    }
    ,
    setCamera: data => {
      dispatch({
        type: 'SET_CAMERA',
        camera: data
      })
    }
    ,
    setStream: data => {
      dispatch({
        type: 'SET_STREAM',
        stream: data
      })
    },
    setVideoDevices: data => {
      dispatch({
        type: 'SET_VIDEO_DEVICES',
        videoDevices: data
      })
    },
    setAudioDevices: data => {
      dispatch({
        type: 'SET_AUDIO_DEVICES',
        audioDevices: data
      })
    }
  }
}



export default connect(mapStateToProps, mapDispatchToProps)(Home)
import React, {Fragment, useState} from "react";
import {Input, Alert, Button} from "reactstrap";
import {Link, useHistory} from 'react-router-dom'
import { prodUrl as url } from "../Config/config.json"
import axios from "axios";
import {toast, ToastContainer} from "react-toastify";
import {connect} from "react-redux";
import parse from "html-react-parser";
import "../styles/login.css";
const Login = (props) => {
    const { setAuth, setName, setUserId } = props;
    const [loginObj, setLoginObj] = useState({ username: "", password: "" });
    const [pesanError, setPesanError] = useState('');
    const history = useHistory();
    const [loginBtnTxt, setLoginBtnTxt] = useState('Login')
    const handleSubmit = () => {
        const email = loginObj.username
        const password = loginObj.password
        changeLoginBtnTxt(parse('<span className="spinner-grow spinner-grow-sm text-center"></span>'))
        axios({
            url: `${url}/signin`,
            method: 'POST',
            data: {
                email,password
            },
            headers: {
                'Content-Type': 'application/json',
            },
        }).then(data => {
            changeLoginBtnTxt('Login')
            setAuth(true)
            setName(data.data.user.name)
            setUserId(data.data.user._id)
            localStorage.setItem('nama_pengguna', data.data.user.name)
            localStorage.setItem('user_id', data.data.user._id)
            localStorage.setItem('user_data', JSON.stringify(data.data.user))
            toast.success("Signed in Successfully", {
                autoClose: 2000,
                closeOnClick: false,
                pauseOnHover: false,
            });
            // set token at local Storage for future services check
            localStorage.setItem('TeamsToken', data.data.token)
            history.push("/dashboard")

        }).catch(err => {
            // if not verified
            changeLoginBtnTxt('Login')
            setPesanError("Email atau Password Salah")
            toast.error("Email atau Password Salah")
        })
    };
    const changeLoginBtnTxt = (val) => {
        setLoginBtnTxt(val);
    }
    return (
        <Fragment>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12">
                        <div className="login-card">
                            <form className="theme-form login-form">
                                <div className="login-header text-center">
                                    <h4>Login</h4>
                                    <h6>Welcome back! Log in to your account.</h6>
                                </div>
                                <div className="login-social-title">
                                    <h5>Sign in with Email</h5>
                                </div>
                                {pesanError !== '' ? (
                                    <Alert color='danger'>
                                        <div className='alert-body font-small-2'>
                                            <p>
                                                {pesanError}
                                            </p>

                                        </div>
                                    </Alert>) : null }
                                <div className="form-group mb-3">
                                    <label>Email</label>
                                    <Input type='text' onChange={(e) => {
                                        setLoginObj({ ...loginObj, username: e.target.value });
                                    }} autoFocus />
                                </div>
                                <div className="form-group mb-3">
                                    <label>Password</label>
                                    <Input type="password" onChange={(e) => {
                                        setLoginObj({ ...loginObj, password: e.target.value });
                                    }} />
                                </div>
                                <div className="form-group">
                                    <div className="checkbox">
                                        <Input type="checkbox" />
                                            <label htmlFor="checkbox1">Remember password</label>
                                    </div>
                                    <a className="link" href="/forgot">Forgot password?</a>
                                </div>
                                <div className="form-group">
                                    <Button onClick={handleSubmit} className="btn btn-primary btn-block">
                                        {loginBtnTxt}
                                    </Button>
                                </div>
                                <div className="new-account mt-3">
                                    <p>Tidak punya akun? <Link to='/register'>Daftar</Link> / <Link to='/'>Home</Link></p>
                                </div>
                            </form>
                    </div>
                </div>
            </div>
        </div>
        <ToastContainer />
        </Fragment>
    )
}
const mapStateToProps = state => {
    return {
        email: state.userDetails.email,
        name: state.userDetails.name,
        userId: state.userDetails.userId,
        auth: state.userDetails.auth
    }
}

const mapDispatchToProps = dispatch => {
    return {

        setEmail: data => {
            dispatch({
                type: 'SET_EMAIL',
                email: data,
            })
        },
        setName: data => {
            dispatch({
                type: 'SET_NAME',
                name: data,
            })
        },
        setUserId: data => {
            dispatch({
                type: 'SET_USER_ID',
                userId: data,
            })
        },
        setAuth: data => {
            dispatch({
                type: 'SET_AUTH',
                auth: data,
            })
        }
    }
}



export default connect(mapStateToProps, mapDispatchToProps)(Login)
import Header from "./layout/Header";
import Sidebar from "./layout/Sidebar";
import Footer from "./layout/Footer";
import React, {Fragment, useEffect, useState} from "react";
import {useHistory} from "react-router-dom";
import {connect} from "react-redux";
import '../styles/homenew.css'
import axios from "axios";
import {prodUrl as url} from "../Config/config.json";
import {toast, ToastContainer} from "react-toastify";
import {makeMeetingId} from "../utils/utils";
import CreateRoom from "./modal/CreateRoom";
const Dashboard = (props) => {
    const {
        name,
        setName,
        userId,
        setUserId,
        setAuth,
        setIsAdmin
    } = props
    const [date, setDate] = useState(new Date());
    const [showCreateRoom, setShowCreateRoom] = useState(false)
    let roomId = makeMeetingId();
    const history = useHistory()
    function refreshClock() {
        setDate(new Date());
    }
    useEffect(() => {
        const timerId = setInterval(refreshClock, 1000);
        return function cleanup() {
            clearInterval(timerId);
        };
    }, []);

    useEffect(() => {
        if(name === '' || name === null || name === undefined) {

            setName(localStorage.getItem('nama_pengguna'))
        }
        if(userId === '' || userId === null || userId === undefined) {
            setUserId(localStorage.getItem('user_id'))
        }
       /* if (!socket) {
            var socketNew = io.connect(`${url}`)

            socketNew.on("connect", () => {
                setSocket(socketNew)

            })

        }*/

    })

    const createRoom = (e,arrData) => {
        e.preventDefault();
       // if (socket) {
        setIsAdmin('yes')
        let needApproval = 'no'
        let passwdRoom=''
        if(arrData.securityOption === 'approval') {
            needApproval = 'yes'
        }
        if(arrData.securityOption === 'password') {
            passwdRoom = arrData.passwordTxt
        }
        axios({
            url: `${url}/createRoom`,
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('TeamsToken')}`
            },
            data: {
                roomID: arrData.meetingId,
                securityOption: arrData.securityOption,
                passwordRoom: passwdRoom,
                needApproval: needApproval,
                socketId: '',
                roomName: undefined
            }
        }).then(data => {
            setShowCreateRoom(false)
            roomId = arrData.meetingId
            history.push(`/teams/${roomId}`)
            toast.info(`Created Room ${roomId}`, {
                position: 'top-left'
            })
        })
        //}
    }

    const onCloseModal = (val) => {
        setShowCreateRoom(val)
    }

    return (
        <Fragment>
            <div id="main-wrapper">
                <Header />
                <Sidebar />
                <div className="page-title dashboard mt-3">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-6">
                                <div className="row">
                                    <div className="col-sm-6">
                                        <div onClick={(e) => setShowCreateRoom(true)} style={{cursor:'pointer'}}>
                                            <div className="auth-form card">
                                                <div className="card-body">
                                                    <div className="identity-content">
                                                        <span className="icon"><i className="fa fa-shield"></i></span>
                                                        <h4>New Meeting</h4>
                                                        <p className="small">Menu untuk buat meeting baru</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-6">
                                        <a href="/join" style={{textDecoration:'none', color: "#000000"}}>
                                            <div className="auth-form card">
                                                <div className="card-body">
                                                    <div className="identity-content">
                                                        <span className="icon"><i className="fa fa-shield"></i></span>
                                                        <h4>Join Meeting</h4>
                                                        <p className="small">Menu untuk gabung meeting</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </a>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-sm-6">
                                        <div className="auth-form card">
                                            <div className="card-body">
                                                <div className="identity-content">
                                                    <span className="icon"><i className="fa fa-shield"></i></span>
                                                    <h4>Schedule</h4>
                                                    <p className="small">Menu untuk penjadwalan meeting baru</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-6">
                                        <div className="auth-form card">
                                            <div className="card-body">
                                                <div className="identity-content">
                                                    <span className="icon"><i className="fa fa-shield"></i></span>
                                                    <h4>Screen Share</h4>
                                                    <p className="small">Menu untuk berbagi layar untuk semua</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="col-xl-12">
                                    <div className="get-touch-content">
                                        <div className="d-flex">
                                            <span><i className="fa fa-clock"></i></span>
                                            <div className="flex-grow-1">
                                                <h1>{date.toLocaleTimeString()}</h1>
                                                <p>Rabu, 18 Maret 2023</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
            <ToastContainer />
            <CreateRoom showModal={showCreateRoom} meetingId={roomId} onSave={(val,objData) => createRoom(val,objData)} onClose={(val) => onCloseModal(val)} />
        </Fragment>
    )
}
const mapStateToProps = state => {
    return {
        name: state.userDetails.name,
        userId: state.userDetails.userId,
        auth: state.userDetails.auth,
        isAdmin: state.userDetails.isAdmin,
        joiningRoom: state.userDetails.joiningRoom,
        joiningPath: state.userDetails.joiningPath,
    }
}

const mapDispatchToProps = dispatch => {
    return {

        setIsAdmin: data => {
            dispatch({
                type: 'SET_IS_ADMIN',
                isAdmin: data,
            })
        },
        setName: data => {
            dispatch({
                type: 'SET_NAME',
                name: data,
            })
        },
        setUserId: data => {
            dispatch({
                type: 'SET_USER_ID',
                userId: data,
            })
        },
        setAuth: data => {
            dispatch({
                type: 'SET_AUTH',
                auth: data,
            })
        },
        setJoiningRoom: data => {
            dispatch({
                type: 'SET_JOINING_ROOM',
                joiningRoom: data,
            })
        },
        setJoiningPath: data => {
            dispatch({
                type: 'SET_JOINING_Path',
                joiningPath: data,
            })
        }
    }
}




export default connect(mapStateToProps, mapDispatchToProps)(Dashboard)
import '../styles/homenew.css'
import {Button, Input, DropdownMenu, DropdownToggle, UncontrolledDropdown} from "reactstrap";
import React, {Fragment, useState} from "react";
import meet_png from '../assets/images/meet.png';
import {connect} from "react-redux";
import { prodUrl as url } from "../Config/config.json"
import axios from "axios";
import {toast, ToastContainer} from "react-toastify";
import {useHistory} from "react-router-dom";
import parse from 'html-react-parser'
import {makeMeetingId} from "../utils/utils";
const Homenew = props => {
    const {
        auth,
        setIsAdmin,
        setSecurityOption
    } = props
    const [joinTxt,setJoinTxt] = useState('');
    const history = useHistory()
    const [joinBtnTxt, setJoinBtnTxt] = useState('Join')
    const handleSubmit = () => {
        //console.log(socket)

        if(joinTxt === '') {
            toast.error("Kode Meeting tidak boleh kosong")
        } else {
            changeBtnJoinTxt(parse('<span className="spinner-grow spinner-grow-sm"></span>'))
            axios({
                url: `${url}/joinRoom`,
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('TeamsToken')}`
                },
                data: {
                    roomID: joinTxt,
                    socketId: ''
                }
            }).then(data => {
                changeBtnJoinTxt('Join')
                if(data.data.status){
                    setSecurityOption(data.data.option.seurityOption)
                    setIsAdmin('no')
                    history.push(`/join-room/${joinTxt}`)
                } else {
                    toast.error("ID Meeting Salah")
                }


            }).catch(err => {
                changeBtnJoinTxt('Join')
                toast.error("ID Meeting Salah")

            })
        }

    }

    const changeBtnJoinTxt = (val) => {
        setJoinBtnTxt(val)
    }

    /*useEffect(() => {
       if (!socket) {
            var socketNew = io.connect(`${url}`)

            socketNew.on("connect", () => {
                //console.log(socketNew)
                setSocket(socketNew)
            })

        }
    }, [socket, setSocket])*/

    const createMeetingInstan = (e) => {
        e.preventDefault();
        if(auth) {
            const roomId = makeMeetingId();
            axios({
                url: `${url}/createRoom`,
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('TeamsToken')}`
                },
                data: {
                    roomID: roomId,
                    socketId: '',
                    roomName: undefined
                }
            }).then(data => {
                history.push(`/teams/${roomId}`)
                toast.info(`Created Room ${roomId}`, {
                    position: 'top-left'
                })
            })

        }
        else {
            history.push(`/signin`)
        }
    }

    return (
        <Fragment>
        <div id="main-wrapper">
            <div className="header">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-12">
                            <div className="navigation">
                                <nav className="navbar navbar-expand-lg navbar-light bg-light">
                                    <a className="navbar-brand" href="/">ALS</a>
                                    <button className="navbar-toggler" type="button" data-toggle="collapse"
                                            data-target="#navbarNavDropdown" aria-controls="navbarNavDropdown"
                                            aria-expanded="false" aria-label="Toggle navigation">
                                        <span className="navbar-toggler-icon"></span>
                                    </button>
                                    <div className="collapse navbar-collapse" id="navbarNavDropdown">
                                        <ul className="navbar-nav">

                                            <li className="nav-item">
                                                <a className="nav-link" onClick={(e) => createMeetingInstan(e)} href="/dashboard">Mulai</a>
                                            </li>

                                        </ul>
                                    </div>
                                    {auth ?
                                        <div className="signin-btn">
                                            <a className="btn btn-primary" href="/dashboard">Dashboard</a>
                                        </div> :
                                        <div className="signin-btn">
                                            <a className="btn btn-primary" href="/signin">Sign in</a>
                                        </div>
                                    }

                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <div className="intro" data-scroll-index="0">
                <div className="container">
                    <div className="row d-flex justify-content-center align-items-center lading-page-height">
                        <div className="col-md-12 col-xl">
                            <p className="fs-2 fs-md-4 text-xl-start text-md-end text-center fw-bold">
                                Premium video meetings.
                                <br /> Now free for everyone.
                            </p>
                            <p className="text-xl-start text-md-end text-center">We re-engineered the service we built for
                                secure business
                                meetings, ALS Conference, to make it free and available for all. </p>

                            <div className="row d-flex justify-content-center align-items-center">
                                <div className=" col-xl-auto">
                                    <UncontrolledDropdown tag='li' className='dropdown'>
                                        <DropdownToggle href='/' tag='a' className='btn btn-primary  dropdown-toggle' onClick={e => e.preventDefault()}>
                                            <i className="fa fa-video-camera"></i> Start Conference
                                        </DropdownToggle>
                                        <DropdownMenu end tag='ul' className='dropdown-menu-media mt-0'>
                                            <li className='dropdown-item'>
                                                <a className="dropdown-item" onClick={(e) => createMeetingInstan(e)} href="/"><i
                                                    className="fa fa-plus me-3"></i>Mulai Rapat Instant</a>
                                            </li>
                                            <li className='dropdown-item'><a className="dropdown-item" href="/"><i
                                                className="fa fa-calendar me-3"></i>Jadwalkan Rapat</a></li>
                                        </DropdownMenu>
                                    </UncontrolledDropdown>

                                </div>
                                <div className="col mt-xl-0 mt-md-2 mt-2 ">
                                    <div className="row align-items-center">
                                        <div className="col-md-9 col-sm-5 col-auto">
                                            <Input type='text' onChange={(e) => {
                                                setJoinTxt(e.target.value);
                                            }} autoFocus />
                                        </div>
                                        <div className="col-md-auto col-auto">
                                            <Button onClick={handleSubmit} className="btn btn-primary d-grid w-100">
                                                {joinBtnTxt}
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                                <hr className="mt-3" />
                                <p className="d-none d-xl-block"><a href="/info" className="text-decoration-none">Pelajari
                                    Lebih Lanjut</a> All Solution Conference</p>
                            </div>
                        </div>
                        <div className="col">
                            <div className="row d-flex justify-content-center align-items-center">
                                <img src="https://als.holdings/images/logo.jpeg" style={{width: "400px"}} alt="logo ALS" />
                                <div className="col-md-8 col-8 text-center">
                                    <p className="fs-4 fw-bold">
                                        Premium Meet features
                                    </p>
                                    <p>Enjoy longer, silencer group video calls
                                        noise, and other interesting features. </p>
                                </div>
                            </div>


                        </div>
                        <p className="d-xl-none d-block"><a href="/info" className=" mt-3 text-decoration-none">Pelajari
                            Lebih Lanjut</a> All Solution Conference</p>

                    </div>
                </div>
            </div>


            <div className="getstart section-padding">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-xl-8">
                            <div className="section-title">
                                <h2>Mulai meeting dalam hitungan menit</h2>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4">
                            <div className="getstart-content">
                                <span><i className="la la-user-plus"></i></span>
                                <h3>Buat akun</h3>
                            </div>
                        </div>
                        <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4">
                            <div className="getstart-content">
                                <span><i className="la la-bank"></i></span>
                                <h3>Buat kode meeting</h3>
                            </div>
                        </div>
                        <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4">
                            <div className="getstart-content">
                                <span><i className="la la-exchange"></i></span>
                                <h3>Share & join</h3>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <div className="portfolio section-padding" data-scroll-index="2">
                <div className="container">
                    <div className="row py-lg-5 justify-content-center">
                        <div className="col-xl-7">
                            <div className="section-title text-center">
                                <h2>Apa yang perlu Anda ketahui tentang ALS Meeting</h2>
                            </div>
                        </div>
                    </div>
                    <div className="row align-items-center justify-content-between">
                        <div className="col-xl-7 col-lg-6">
                            <div className="portfolio_list">
                                <div className="row">
                                    <div className="col-xl-6">
                                        <div className="d-flex">
                                            <span className="port-icon"> <i className="la la-bar-chart"></i></span>
                                            <div className="flex-grow-1">
                                                <h4>Rapat dengan aman</h4>
                                                <p>Meet menggunakan perlindungan yang sama dengan yang diterapkan untuk
                                                    mengamankan informasi dan menjaga privasi Anda.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-xl-6">
                                        <div className="d-flex">
                                            <span className="port-icon"> <i
                                                className="la la-calendar-check-o"></i></span>
                                            <div className="flex-grow-1">
                                                <h4>Rapat dari mana saja</h4>
                                                <p>Undang semuanya ke ALS Collabs, di mana Anda dapat mempresentasikan,
                                                    berkolaborasi , atau sekadar saling menyapa secara langsung.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-xl-6">
                                        <div className="d-flex">
                                            <span className="port-icon"> <i className="la la-lock"></i></span>
                                            <div className="flex-grow-1">
                                                <h4>Rapat di segala perangkat dari mana saja</h4>
                                                <p>Tamu dapat bergabung dari komputernya menggunakan browser web modern
                                                    apa pun—tanpa perlu menginstal software. Di perangkat seluler,
                                                    mereka dapat bergabung dari aplikasi.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-xl-6">
                                        <div className="d-flex">
                                            <span className="port-icon"> <i className="la la-mobile"></i></span>
                                            <div className="flex-grow-1">
                                                <h4>Rapat dengan audio yang jelas</h4>
                                                <p>ALS Collabs menyesuaikan performanya dengan kecepatan jaringan Anda,
                                                    sehingga Anda dapat menikmati panggilan video berkualitas tinggi di
                                                    mana pun berada.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-5 col-lg-6">
                            <div className="portfolio_img">
                                <img src={meet_png} alt="meet png" className="img-fluid" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="trade-app section-padding">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-xl-6">
                            <div className="section-title text-center">
                                <h2>Rapat di segala perangkat</h2>
                                <p> Semua orang dapat terhubung dengan berbagai perangkat yang tersedia
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xl-4 col-lg-4 col-md-12">
                            <div className="card trade-app-content">
                                <div className="card-body">
                                    <span><i className="la la-mobile"></i></span>
                                    <h4 className="card-title">Mobile</h4>
                                    <p>Donwload aplikasi ALS Collabs di Google Play Store</p>

                                    <a href="/download"> Downloads <i className="la la-arrow-right"></i> </a>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-4 col-lg-4 col-md-12">
                            <div className="card trade-app-content">
                                <div className="card-body">
                                    <span><i className="la la-desktop"></i></span>
                                    <h4 className="card-title">Desktop</h4>
                                    <p>Silahkan download aplikasi desktop kami sesuai dengan sistem operasi yang Anda
                                        gunakan</p>

                                    <a href="/download"> Downloads <i className="la la-arrow-right"></i> </a>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-4 col-lg-4 col-md-12">
                            <div className="card trade-app-content">
                                <div className="card-body">
                                    <span><i className="la la-connectdevelop"></i></span>
                                    <h4 className="card-title">Browser</h4>
                                    <p>Anda juga dapat terhubung dengan menggunakan browser web modern apa pun—tanpa
                                        perlu menginstal software</p>

                                    <a href="/download"> Info lanjut <i className="la la-arrow-right"></i> </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
            <ToastContainer />
        </Fragment>
    )
}


const mapStateToProps = state => {
    return {
        auth: state.userDetails.auth,
        name: state.userDetails.name,
        userId: state.userDetails.userId,
        isAdmin: state.userDetails.isAdmin,
        securityOption: state.userDetails.securityOption,
        joiningRoom: state.userDetails.joiningRoom,
        joiningPath: state.userDetails.joiningPath,
    }
}

const mapDispatchToProps = dispatch => {
    return {

        setName: data => {
            dispatch({
                type: 'SET_NAME',
                name: data,
            })
        },
        setUserId: data => {
            dispatch({
                type: 'SET_USER_ID',
                userId: data,
            })
        },
        setAuth: data => {
            dispatch({
                type: 'SET_AUTH',
                auth: data,
            })
        },
        setIsAdmin: data => {
            dispatch({
                type: 'SET_IS_ADMIN',
                isAdmin: data,
            })
        },
        setSecurityOption: data => {
            dispatch({
                type: 'SET_SECURITY_OPTION',
                securityOption: data,
            })
        },
        setJoiningRoom: data => {
            dispatch({
                type: 'SET_JOINING_ROOM',
                joiningRoom: data,
            })
        },
        setJoiningPath: data => {
            dispatch({
                type: 'SET_JOINING_Path',
                joiningPath: data,
            })
        }
    }
}



export default connect(mapStateToProps, mapDispatchToProps)(Homenew)
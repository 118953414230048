import React, { useState } from "react";
import { useHistory } from 'react-router-dom'
import { connect } from 'react-redux';
import { ToastContainer, toast } from 'react-toastify';
import "../styles/signup.css"
import axios from 'axios';
import { prodUrl as url } from "../Config/config.json"
import {Button, Input} from "reactstrap";
import "../styles/login.css";
const ForgotPassword = (props) => {
    // set context user data
    const [email, setEmail]=useState('');
    // for redirect
    const history = useHistory()

    // otp hashed receive and email id sent
    const forgotpassword = () => {
        if (!email)
            return toast.error("Email can't be empty")
        //signup via server
            axios({
            url: `${url}/forgotpassword`,
            method: 'POST',
            data: {
                email
            },
            headers: {
                'Content-Type': 'application/json',
            },
        }).then(data => {
            if(data.data.status){
                toast.success("Password Updated Successfully", {
                    autoClose: 2000,
                    closeOnClick: false,
                    pauseOnHover: false,
                });
                setTimeout(() => {
                    history.push("/signin")
                }, 1000);
            } else {
                toast.error(data.data.message)
            }


        }).catch(data=>{
            toast.error("Terjadi kesalahan...")
        })
    }
    return (
        <>
            <div className="container-fluid">
                <div className="row m-0">
                    <div className="col-12 p-0">
                        <div className="login-card">
                            <div className="login-main">
                                <form className="theme-form login-form">
                                    <h4>Lupa password </h4>
                                    <div className="form-group">
                                       <label className="col-form-label">Masukkan Email Anda</label>
                                       <Input
                                            type="email"
                                            placeholder="Enter Email ID"
                                            className="email-input"
                                            value={email}
                                            onChange={(e) => { setEmail(e.target.value) }}/>
                                    </div>
                                    <div className="form-group">
                                        <Button className="btn btn-primary btn-block"
                                                onClick={() => {
                                                    forgotpassword()
                                                }}>
                                                Submit
                                        </Button>
                                    </div>
                                    <p className="text-start">Sudah punya akun?<a className="ms-2"
                                                                                         href="/signin">Sign in </a>
                                    </p>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ToastContainer />
        </>

    )
}



export default connect(null,null)(ForgotPassword)
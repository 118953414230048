import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import 'bootstrap/dist/css/bootstrap.css';
import reportWebVitals from './reportWebVitals';
import {Provider} from 'react-redux'
import Reducer from "./Reducer/Reducer"
import {createStore} from 'redux';
import './assets/icons/flag-icon-css/flag-icon.min.css';
import './assets/icons/font-awesome/css/font-awesome.min.css';
import './assets/icons/cryptocoins/css/cryptocoins.css';
import './assets/icons/cryptocoins/css/cryptocoins-colors.css';
import './assets/icons/line-awesome/css/line-awesome.css';
import storage from 'redux-persist/lib/storage';
import { persistReducer, persistStore, createTransform } from 'redux-persist';
import { PersistGate } from 'redux-persist/integration/react';
import JSOG from 'jsog'

export const JSOGTransform = createTransform(
    (inboundState, key) => JSOG.encode(inboundState),
    (outboundState, key) => JSOG.decode(outboundState),
)

const persistConfig = {
    key: 'rootMeet',
    storage,
    transforms: [JSOGTransform]
}
const persistedReducer = persistReducer(persistConfig, Reducer)
const store = createStore(persistedReducer, window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__());
const token = localStorage.getItem('TeamsToken')
const storePersist = persistStore(store)
// Extract token to check for authorization
if (token) {
  store.dispatch({type: 'SET_AUTH', auth: true})
}
else{
  store.dispatch({type: 'SET_AUTH', auth: false})
}

ReactDOM.render(
  <Provider store={store} >
      <PersistGate loading={null} persistor={storePersist}>
            <App />
      </PersistGate>
  </Provider>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

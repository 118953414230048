const Sidebar = () => {
    return (
        <div className="sidebar">
            <div className="menu-dash">
                <ul>
                    <li >
                        <a href="/dashboard" data-toggle="tooltip" title="Home Dashboard">
                            <span><i className="fa fa-dashboard"></i></span>
                        </a>
                    </li>
                    <li>
                        <a href="/calender" data-toggle="tooltip" title="Accounts">
                            <span><i className="fa fa-calendar"></i></span>
                        </a>
                    </li>
                    <li>
                        <a href="/setting" data-toggle="tooltip" title="Setting">
                            <span><i className="fa fa-gear"></i></span>
                        </a>
                    </li>
                </ul>
            </div>
        </div>
    )
}

export default Sidebar;